<template>
  <div>

    <div class="main" style="background:#fff;">
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <md-card class="bg-success h-100">
                <md-card-content>
                  <h3 class="card-category card-category-social text-white">
                    <i class="far fa-newspaper" /> Pricing
                  </h3>
                  <h4 class="card-title text-white">
                    Lists for your Products
                  </h4>
                  <p class="card-description text-white">
                    Create the pricing lists you can then assign to your
                    customers. A customer can have 1 price list per channel
                    only. <br />You can also assign a company a price list in
                    the CRM.
                  </p>

                  <div class="card-stats text-center"></div>
                </md-card-content>
              </md-card>
            </div>
            <div class="col-lg-8">
              <md-card class="bg-primary h-100">
                                <md-card-content>
               
                  <p class="card-description text-white">
                  Great Content Coming Soon
                  </p>

                  <div class="card-stats text-center"></div>
                </md-card-content>
                
                
                
                 </md-card>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <v-card>
                <v-data-table
                  :headers="headers"
                  :items="pricelists"
                  class="elevation-1"
                >
                  <template #top>
                    <v-toolbar flat>
                      <v-toolbar-title>Price Lists</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <v-btn color="primary" dark @click="addDialog = true">
                        New List
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template #item="row">
                    <tr>
                      <td>
                        <template
                          v-if="
                            default_pricelist != row.item.id &&
                              row.item.noexpiration != true &&
                              isExpired(row.item.expiration)
                          "
                        >
                          <span style="text-decoration: line-through;">
                            {{ row.item.listname }}
                          </span>
                        </template>
                        <template v-else>
                          <span v-if="default_pricelist == row.item.id">
                            {{ row.item.listname }} (default)
                          </span>
                          <span v-else> {{ row.item.listname }} </span>
                        </template>
                      </td>

                      <td>
                        <template
                          v-if="
                            row.item.noexpiration != true &&
                              isExpired(row.item.expiration)
                          "
                        >
                          <span class="text-muted" style=" color:#ff0f31">
                            <span
                              v-if="
                                row.item.noexpiration == true ||
                                  default_pricelist == row.item.id
                              "
                            >
                              No Expiration
                            </span>
                            <span v-else-if="row.item.expiration != undefined"
                              >{{ formatDate(row.item.expiration) }}
                            </span>
                          </span>
                        </template>

                        <template v-else>
                          <span
                            v-if="
                              row.item.noexpiration == true ||
                                default_pricelist == row.item.id
                            "
                          >
                            No Expiration
                          </span>
                          <span v-else-if="row.item.expiration != undefined">{{
                            formatDate(row.item.expiration)
                          }}</span>
                        </template>
                      </td>

                      <td>
                        <template
                          v-if="
                            row.item.noexpiration != true &&
                              isExpired(row.item.expiration) &&
                              default_pricelist != row.item.id
                          "
                        >
                          <span style=" color:#ff0f31"> Expired </span>
                        </template>

                        <template v-else>
                          Active
                        </template>
                      </td>
                      <td>
                        <v-btn
                          text
                          color="primaryt"
                          @click="
                            $router.push('/pim/price-detail/' + row.item.id)
                          "
                        >
                          <v-icon left>
                            mdi-pencil
                          </v-icon>
                          Edit
                        </v-btn>

                        <v-btn
                          v-if="default_pricelist != row.item.id"
                          text
                          color="primaryt"
                          @click="deleteItem(row.item.id)"
                        >
                          <v-icon left>
                            mdi-delete
                          </v-icon>
                          Remove
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </div>

          <v-dialog v-model="addDialog" persistent max-width="600px">
            <v-form v-model="addDialogformvalid" @submit.prevent="addPriceList">
              <v-card>
                <v-card-title>
                  <span class="headline">Add New Price List</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="addDialogform.name"
                          label="Name"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-checkbox
                          v-model="noExpiration"
                          label="No Expiration"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-menu
                          ref="expireDateMenu"
                          v-model="expireDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template #activator="{ on, attrs }">
                            <v-text-field
                              v-model="expireDateFormatted"
                              label="Expiration Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              :disabled="noExpiration"
                              @blur="
                                expireDate = parseDate(expireDateFormatted)
                              "
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="expireDate"
                            no-title
                            @input="expireDateMenu = false"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="expireDateMenu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.expireDateMenu.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="addDialog = false">
                    Close
                  </v-btn>
                  <v-btn
                    type="submit"
                    :disabled="!addDialogformvalid"
                    color="blue darken-1"
                    text
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import { mapActions } from "vuex";

export default {
  data() {
    return {
      default_pricelist: 0,
      noExpiration: false,
      pricelists: [],
      expireDate: new Date().toISOString().substr(0, 10),
      expireDateFormatted: this.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      expireDateMenu: false,
      addDialog: false,
      addDialogformvalid: false,
      addDialogform: {
        name: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => "The email and password you entered don't match",
      },
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "List Name",
          align: "start",

          value: "listname",
        },

        {
          text: "Expiration Date",
          value: "expiration",
        },
        {
          text: "status",
          value: "status",
        },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    expireDate() {
      this.expireDateFormatted = this.formatDate(this.expireDate);
    },
  },

  created() {
    this.getPricelist().then((res) => {
      this.pricelists = res.data;
    });

    setTimeout(() => {
      this.getCompanyInfo({
        id: this.$store.state.auth.user.company_relation,
      }).then((cres) => {
        if (cres.data.default_pricelist != null) {
          this.default_pricelist = cres.data.default_pricelist.id;
        }
      });
    }, 1000);
  },
  methods: {
    ...mapActions([
      "submitPricelist",
      "removePricelist",
      "getPricelist",
      "getCompanyInfo",
    ]),
    isExpired(date) {
      var today = new Date().toISOString();
      var exp = new Date(date).toISOString().substr(0, 11) + "23:59:59.999Z";
      if (exp >= today) {
        return false;
      }
      return true;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}-${day}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Confirm",
          size: "sm",
          buttonSize: "md",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.removePricelist({
              id: id,
            }).then(() => {
              this.getPricelist().then((res) => {
                this.pricelists = res.data;
              });
              Swal.fire({
                title: "",
                text: "Removed successfully!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
            });
          }
        });
    },
    addPriceList() {
      this.submitPricelist({
        listname: this.addDialogform.name,
        expiration: this.expireDate,
        noexpiration: this.noExpiration,
      }).then((res) => {
        if (res.data != false) {
          this.addDialog = false;
          this.getPricelist().then((res) => {
            this.pricelists = res.data;
          });
          Swal.fire({
            title: "",
            text: "The price list has been successfully created!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });
        } else {
          this.addDialog = false;
          Swal.fire({
            title: "",
            text: "Error occured",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.checkboxpublish .v-icon {
  font-size: 28px !important;
}
.img-raised {
  -webkit-box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.54),
    0 8px 10px -5px rgba(0, 0, 0, 0.7);
}

.md-card-pricing ul {
  list-style: none;
  padding: 0;
  max-width: 240px;
  margin: 10px auto;
}
.md-card-pricing ul li {
  color: #fff;
  text-align: center;
  padding: 12px 0;
  font-size: 1.5rem;
  border-bottom: 3px solid rgba(255, 255, 255, 0.3);
}

.follow {
  position: absolute;
  top: -28px;
  right: 7px;
}

.lato-header {
  margin-top: 2px;
  margin-bottom: 117px;
  min-height: 32px;
  font-family: "Lato", sans-serif;
  font-size: 4rem;
  font-weight: 800;
  line-height: 4rem;
  letter-spacing: 0.0125em !important;
  text-shadow: #ccc;
}

.lato {
  margin-top: 30px;
  margin-bottom: 161px;
  min-height: 32px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
}
.section-text {
  p {
    font-size: 1.188rem;
    line-height: 1.5em;
    color: #333;
    margin-bottom: 30px;
  }
  .blockquote {
    p {
      font-size: 1.5rem;
    }
  }
}

.section-blog-info {
  padding-top: 30px;
  padding-bottom: 0;

  .btn {
    margin-top: 0;
    margin-bottom: 0;
  }
  .blog-tags {
    padding-top: 8px;
  }
  .card-profile {
    margin-top: 0;
    text-align: left;

    .description {
      font-size: 18px;
    }
    .btn {
      margin-top: 25px;
    }
  }
}
.section-comments {
  .title {
    margin-bottom: 30px;
  }
}

.comment {
  p {
    color: #ccc;
  }
}

.section-projects-1 {
  .title {
    margin-top: 10px;
  }
}

.section-projects-2 {
  .title {
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .description {
    margin-bottom: 130px;
  }
  .card-title {
    margin-bottom: 5px;
  }
  .category {
    color: #ccc;
    margin-top: 5px;
  }
  .card-description {
    margin-top: 20px;
  }
  .md-card {
    text-align: center;
  }
}

.back-background .card-title,
.front-background .card-title,
.md-card-background .card-title {
  color: #ffffff !important;
  margin-top: 10px;
}

.section-projects-1 {
  .title {
    margin-top: 10px;
  }
}

.section-projects-2 {
  .title {
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .description {
    margin-bottom: 130px;
  }
  .card-title {
    margin-bottom: 5px;
  }
  .category {
    color: #ccc;
    margin-top: 5px;
  }
  .card-description {
    margin-top: 20px;
  }
  .md-card {
    text-align: center;
  }
}

.section-projects-3 {
  .title {
    margin-bottom: 80px;
    margin-top: 5px;
  }

  .md-card {
    margin-bottom: 30px;
    margin-top: 60px;
    text-align: center;
  }
  .category {
    margin-bottom: 5px;
  }
  .card-title {
    margin-top: 5px;
  }
}

.section-projects-4 {
  .title {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .title + .description {
    margin-bottom: 130px;
  }

  .info {
    padding: 0;

    p {
      margin: 0;
    }
  }

  .md-card-background {
    margin-bottom: 0;

    .md-card-content {
      padding-top: 140px;
      padding-bottom: 140px;
    }
  }
  hr {
    margin: 70px auto;
    max-width: 970px;
  }
  .md-card {
    margin-top: 0;
  }
}

.skew-separator {
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 800px;
    -webkit-transform: skewY(-8deg);
    transform: skewY(-8deg);
    background: #fff;
    top: 88%;
    z-index: 1;
  }

  &.skew-top {
    &:after {
      height: 370px !important;
      top: -220px !important;
    }
  }

  &.skew-mini {
    &:after {
      top: 79% !important;
    }
  }
}
.section-shaped {
  position: relative;
  overflow: hidden;

  &.section-hero {
    &:before {
      top: 680px;
    }
  }

  .stars-and-coded {
    margin-top: 8rem;
  }

  .shape {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    span {
      position: absolute;
    }

    + .container {
      position: relative;
      // padding-top: 6rem;
      // padding-bottom: 0rem;
      height: 100%;
    }

    &.shape-skew {
      + .container {
        padding-top: 0;

        .col {
          margin-top: -100px;
        }
      }

      + .shape-container {
        padding-top: 18rem;
        padding-bottom: 19rem;
      }
    }
  }

  .shape-skew {
    transform: skewY(-4deg);
    transform-origin: 0;

    span {
      transform: skew(4deg);
    }
  }

  // Color variations
}
</style>

<style scoped>
.title {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
  font-family: "Lato", sans-serif;
}
.v-application .title {
  font-size: 2.75rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Lato", sans-serif;
}

.page-header {
  height: 100vh;
  background-position: 50%;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main-raised {
  margin: -220px 30px 0px;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.main {
  background: #eee;
  position: relative;
  z-index: 3;
}
.md-button:not(.md-just-icon):not(.md-btn-fab):not(.md-icon-button):not(.md-toolbar-toggle)
  .md-ripple {
  padding: 12px 30px;
}
.md-button .md-ripple {
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.md-ripple {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
  overflow: hidden;
  -webkit-mask-image: radial-gradient(circle, #fff 100%, #000 0);
}
</style>
